import React, { useEffect } from "react";
import '../components/about.css';


const AboutScreen = () => {
    useEffect(() => {
        window.scrollTo (0, 0);
    
      }, []);

      const citaLink = 'https://wa.me/522382225136?text=Quiero%20agendar%20una%20cita';
      
    return (
        <div className="about-container">
            <h1 className="title-elizabeth">Lic. Elizabeth Zavaleta</h1>
            <h2 className="title-rol">Contaduría e Impuestos</h2>
            <div className="about-info">
                <div className="profile-picture">
                    <img src={process.env.PUBLIC_URL + '/media/profile-picture.png'} alt="Foto de perfil de Elizabeth" />
                </div>
                <p>Con más de 10 años de experiencia, egresada del Instituto Tecnológico de Tehuacan en 2013. Especializada en contabilidad, impuestos y apelación de multas, Elizabeth destaca por hacer que las finanzas sean comprensibles y manejables. Para ella, los números no son un misterio, ¡son su especialidad!</p>
                <br/>
                <p>Cuenta con amplia experiencia en distintos sectores y esta en constante actualización para estar siempre al día.</p>
                <button className='about-btn' onClick={() => window.open(citaLink, '_blank')}>
          Agendar Cita</button>
            </div>
            <div className="mission-vision">
                <div className="mission">
                    <h2>Misión</h2>
                    <p>En nuestro despacho contable, nos dedicamos a simplificar la complejidad financiera para nuestros clientes. Trabajamos con pasión y dedicación para ofrecer soluciones contables y fiscales que impulsen el crecimiento y la tranquilidad financiera. Nuestra misión es ser socios confiables, brindando asesoría experta que permita a nuestros clientes enfocarse en lo que realmente importa: hacer crecer sus negocios.</p>
                </div>
                <div className="vision">
                    <h2>Visión</h2>
                    <p>
                        Nos visualizamos como el despacho contable de referencia, reconocido por nuestra capacidad para transformar desafíos financieros en oportunidades de éxito. Aspiramos a ser líderes en innovación contable, utilizando tecnología de vanguardia para ofrecer servicios eficientes y personalizados. Nuestra visión es ser el respaldo constante de nuestros clientes, permitiéndoles alcanzar sus metas financieras con confianza y claridad.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default AboutScreen;