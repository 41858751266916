import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ post }) => {
  const { id, image, title, description, date, autor } = post;
  const timestamp = new Date().toLocaleString(); // Get current date and time as a string

  return (
    <div className="blog-card">
      <img src={image} alt={title} className="blog-card-image" />
      <div className="blog-card-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <small>{date}</small>
        <br/>
        <small> Por: {autor} </small>
        <Link to={`/article/${id}`}>Leer más</Link>
      </div>
    </div>
  );
};

export default BlogCard;
