// BlogScreen.jsx
import React, { useEffect } from 'react';
import BlogCard from '../components/BlogCard';
import blogData from '../data/blogData.json';
import '../components/about.css';

const BlogScreen = () => {
  useEffect(() => {
    window.scrollTo (0, 0);

  }, []);
  
  return (
    <div>
      <div className='blog-title-c'>
        <h2>"CUENTAS CLARAS"</h2>
        <p>Explorando el mundo de la contabilidad y los impuestos.</p>
      </div>
      <div className="blog-screen">
        {blogData.map((post, index) => (
          <BlogCard key={index} post={post} />
        ))}
      </div>
    </div>
  );
};

export default BlogScreen;
