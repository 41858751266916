import React, { useEffect } from "react";
import PlansSection from "../components/PlanSection";

const PlansScreen = () => {
    useEffect(() => {
        window.scrollTo (0, 0);
    
      }, []);

      const citaLink = 'https://wa.me/522382225136?text=Quiero%20agendar%20una%20cita';
      
    return (
        <div className="plans-tab">
            <div>
                <h2 className="plans-title">Elige tu plan</h2>
            </div>
            <PlansSection></PlansSection>
            <div className="plans-text">
                <p>
                    Si lo que requieres es una atención personalizada no olvides que podemos hacer un plan a tu medida.
                </p>
                <button className='about-btn' onClick={() => window.open(citaLink, '_blank')}>
          Agendar Cita</button>
            </div>
        </div>

    )
}

export default PlansScreen;