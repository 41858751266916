import React, { useEffect, useState } from "react";
import PlansSection from "../components/PlanSection";


const MainScreen = () => {
    useEffect(() => {
        window.scrollTo (0, 0);
    
      }, []);
      

    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [contactoWhatsapp, setContactoWhatsapp] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Aquí puedes realizar la lógica para enviar los datos del formulario
        console.log('Datos enviados:', { nombre, apellido, telefono, correo });
    };

    return (
        <div>
            <div className="section-1">
                <div className="section-1-c">
                    <div className="s-1-txt">
                        <h1>El mejor asesor contable<span style={{ color: "#00BF63" }}> en línea</span></h1>
                        <h2 style={{ color: "#004AAD" }}>La evolución es importante y adaptarnos a las nuevas tecnologías
                            nos da la pauta para ofrecer servicios contables sin la limitación de la distancia.</h2>
                        <button className="d-f-btn">
                            <a href="https://wa.me/522382225136?text=Quiero%20mi%20diagn%C3%B3stico%20fiscal%20gratis">
                                <p>Diagnóstico Fiscal Gratis</p>
                            </a>
                        </button>
                        <p style={{ fontStyle: "italic", color: "#5E17EB" }}>Planes desde $499.00 + IVA</p>
                    </div>
                    <div className="section-1-picture">
                        <img src={process.env.PUBLIC_URL + '/media/solcont-pic-wc.png'} alt="solcont-pic-graphic"></img>
                    </div>
                </div>
            </div>
            <section className="plans-s-2">
                <h2>Planes para Personas Físicas</h2>
                <PlansSection />

            </section>

            <section className="serv-section">
                <h2 style={{ textAlign: "center", padding: "5% 20%", color: "#5E17EB" }}>Más de 10 años de expreciencia nos respaldan. Expertos en impuestos, contaduría general y consultoría fiscal.</h2>
                <div class="parent">
                    <div class="div1">
                        <div style={{ maxWidth: "50%" }}>
                            <h2>Deja que nosotros nos hagamos cargo de tu contabilidad.</h2>
                            <p>Sabemos lo importante que es tu negocio, y por eso contar con un asesor contable de confianza y con experiencia es primordial. </p>
                        </div>
                        <div className="image-grid1" style={{ maxWidth: "50%" }}>
                            <img className="gird1" src={process.env.PUBLIC_URL + '/media/grid1.png'} alt="solcont-pic-graphic"></img>
                        </div>
                    </div>
                    <div class="div2">
                        <div>
                            <p>Registro y análisis de transacciones financieras para proporcionar información precisa y útil a empresas.</p>
                            <h2>Contabilidad General</h2>
                            <img className="gird2" src={process.env.PUBLIC_URL + '/media/grid2.png'} alt="solcont-pic-graphic"></img>
                        </div>
                    </div>
                    <div class="div3">
                        <div>
                            <p>Expertos en optimizar obligaciones fiscales, brindando asesoramiento estratégico para empresas y particulares.</p>
                            <h2>Impuestos</h2>
                            <img className="gird2" src={process.env.PUBLIC_URL + '/media/grid3.png'} alt="solcont-pic-graphic"></img>
                        </div>
                    </div>
                    <div class="div4">
                        <div>
                            <p>Servicio personalizado de consultoría para abordar tus necesidades específicas, garantizando soluciones adaptadas a tu situación individual.</p>
                            <h2>AsesorÍa 1 a 1 (O2O)</h2>
                            <img className="gird2" src={process.env.PUBLIC_URL + '/media/grid4.png'} alt="solcont-pic-graphic"></img>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mp-s3" style={{}}>
                <div>
                    <h2 style={{ fontSize: "24px" }}>
                        Tu contabilidad en línea
                    </h2>
                    <p style={{ color: "#888b94", fontSize: "18PX" }}>En SolCont, ofrecemos servicios de contabilidad en línea a todo México, especialmente diseñados para personas físicas.        </p>
                    <p style={{ color: "#888b94", fontSize: "18PX" }}>Ofrecemos planes de suscripción de acuerdo a tus necesidades. Estamos listos para ayudarte a aclarar tus dudas y proporcionarte asesoramiento personalizado, todo desde la comodidad de tu hogar u oficina.</p>
                    <p style={{ color: "#888b94", fontSize: "18PX" }}>Contáctanos y descubre como la contabilidad puede ser más fácil de lo que pensabas.</p>

                </div>
                
            </section>

        </div>
    )
}

export default MainScreen;