import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import AboutScreen from './screens/AboutScreen';
import PlansScreen from './screens/PlansScreen';
import BlogScreen from './screens/BlogScreen';
import ArticleDetail from './screens/BlogArticleScreen';
import ErrorPage from './screens/ErrorScreen';


function App() {
  return (
    <BrowserRouter>
      <Header></Header>
      <Routes className="app">
        <Route path='/' element={<MainScreen />} />
        <Route path='/about' element={<AboutScreen />} />
        <Route path='/plans' element={<PlansScreen />} />
        <Route path='/blog' element={<BlogScreen />} />
        <Route path="/article/:id" element={<ArticleDetail />} />
      </Routes>
      <Footer></Footer>
    </BrowserRouter>
  )


}

const Header = () => {
  const contaxLogo = `${process.env.PUBLIC_URL}/media/solcont.png`;
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !scrolled) {
        setScrolled(true);
      } else if (window.scrollY === 0 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <div className={`container ${isMenuOpen ? 'menu-open' : ''}`}>
        <Link to={"/"}>
          <img className="logo" src={contaxLogo} alt="SolCont | Despacho Contable" /></Link>
        <button id='menu' className='button-menu' onClick={toggleMenu}>
          <i className="fas fa-bars hamburger-menu"></i>
        </button>

        {isMenuOpen && (
          <div className="overlay" onClick={closeMenu}></div>
        )}

        <div className={`menu-container ${isMenuOpen ? 'menu-open' : ''}`} onClick={closeMenu}>
          <ul>
            <li>
              <Link className='menu-mobile' to={"/"} onClick={closeMenu}>
                Inicio
              </Link>
            </li>
            <li>
              <Link className="menu-mobile" to={"/about"} onClick={closeMenu}>
                ¿Quiénes Somos?
              </Link>
            </li>
            <li>
              <Link className="menu-mobile" to={"/plans"} onClick={closeMenu}>
                Planes
              </Link>
            </li>
            <li>
              <Link className='menu-mobile' to={"/blog"} onClick={closeMenu}>
                Blog
              </Link>
            </li>
            <li>
              <a className="menu-mobile" href="tel:2382225136" onClick={closeMenu}>
                <i className='fa-solid fa-phone icon-space icon-space' ></i>
                238-222-5136
              </a>
            </li>
            <li className={{ color: "#59CE72" }}>
              <a href="https://wa.me/522382225136" onClick={closeMenu}>
                <i className='fa-brands fa-whatsapp icon-space'></i>
                WhatsApp
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className={`header ${scrolled ? 'header-scroll' : ''}`}>
        <div className="logo-container">
          <Link to={"/"}>
            <img className="logo" src={contaxLogo} alt="Solcont"></img>
          </Link>
        </div>
        <div className={`menu-links ${scrolled ? 'menu-links-scrolled' : ''}`}>
          <ul className='linklist'>
            <li>
              <Link to={"/"}>Inicio</Link>
            </li>
            <li>
              <Link to={"/about"}>
                ¿Quiénes Sómos?
              </Link>
            </li>
            <li>
              <Link to={"/plans"}>Planes</Link>
            </li>
            <li>
              <Link to={"/blog"}>Blog</Link>
            </li>
            <li>
              <a href="https://wa.me/522382225136?text=Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank" rel="noopener noreferrer">
                Contáctanos
              </a>
            </li>


          </ul>
        </div>


      </div>
    </div>
  );
};


const Footer = () => {


  const contaxLogo = `${process.env.PUBLIC_URL}/media/solcont.png`;
  const isaSoftLogo = `${process.env.PUBLIC_URL}/media/isasoft.png`;
  return (
    <footer>
      <div>
        <img className="footer-logo" src={contaxLogo} alt="SolCont | Despacho Contable" />
      </div>
      <div>
        <ul className='footer-links'>
          <li>
            <Link to={"/about"}>Info</Link>
          </li>
          <li>
            <Link to={"/plans"}>Planes</Link>
          </li>
          <li>
            <Link to={"/blog"}>Blog</Link>
          </li>
        </ul>
      </div>
      <div>
        <h3 className='social-media-title'>
          Siguenos en nuestras redes.
        </h3>
        <ul className="social-icons">
          <li>
            <a href="https://www.instagram.com/solcont_mx" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/SolucionesContablesTh" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://wa.me/522382225136?text=Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
          </li>
          <li>
            <a href="mailto:elizabeth.zavaleta@solcont.com.mx" target="_blank" rel="noopener noreferrer">
              <i className="fas fa-envelope"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className='rights-container'>
        <small className='rights'>
          Todos los derechos reservados 2023
        </small>
        <a className='isasoft-container' href='https://www.isasoft.com.mx'>
          <img className='isasoftlogo' src={isaSoftLogo} alt="isaSoft " />
        </a>
      </div>
    </footer>

  );
}




export default App;