import React from 'react';
import plansData from '../data/plansData.json';
import '../planCards.css';

const PlansSection = () => {
  return (
    <section className="plans-section">
      {plansData.map((plan, index) => (
        <PlanCard key={index} {...plan} />
      ))}
    </section>
  );
};

const PlanCard = ({ title, price, features, regime }) => {
  const whatsappLink = `https://wa.me/522382225136?text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20del%20plan%20${encodeURIComponent(title)}`;

  return (
    <div className="plan-card">
      <h2 className='title'>{title}</h2>
      <p className="price">{price}</p>
      <p className='description'>{regime}</p>
      <ul>
        {features.map((feature, index) => (
          <li key={index}><i className="fas fa-check"></i> {feature}</li>
        ))}
      </ul>
      <div className='btn-container'>
        <button className='btn' onClick={() => window.open(whatsappLink, '_blank')}>
          Más Información
        </button>
      </div>
    </div>
  );
};

export default PlansSection;
